// hooks/useWallet.js
import { usePrivy } from '@privy-io/react-auth';

export const useWallet = () => {
  const { 
    login, 
    logout, 
    loading, 
    user 
  } = usePrivy();

  return {
    walletAddress: user?.wallet?.address || null,
    isConnecting: loading,
    connectWallet: login,
    disconnectWallet: logout
  };
};