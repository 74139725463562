import React, { useRef, useState, useEffect } from 'react';
import { useScroll } from 'framer-motion';
import Header from './main/Header';
import AboutSection from './main/about';
import CurrentSection from './main/current';
import HeroSection from './main/hero';
import UpcomingFeatures from './main/features';

export const Official = () => {
  const containerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const { scrollYProgress } = useScroll({
    offset: ["start start", "end start"],
    smooth: true,
    damping: 15
  });

  return (
    <div className="relative w-full">
      {/* Global Header */}
      <Header />

      {isMobile ? (
        // Mobile version - Only show CurrentSection
        <CurrentSection scrollProgress={scrollYProgress} />
      ) : (
        // Desktop version - Show all sections
        <div ref={containerRef} className="relative w-full overflow-hidden">
          <div className="block lg:sticky lg:top-0 h-screen">
            <HeroSection scrollProgress={scrollYProgress} />
          </div>
          
          <div className="block lg:sticky lg:top-0 h-screen">
            <AboutSection scrollProgress={scrollYProgress} />
          </div>
          
          <div className="block lg:sticky lg:top-0 h-screen">
            <CurrentSection scrollProgress={scrollYProgress} />
          </div>
          
          <div className="block lg:sticky lg:top-0 h-screen">
            <UpcomingFeatures scrollProgress={scrollYProgress} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Official;  