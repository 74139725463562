import React from 'react';
import { motion } from 'framer-motion';

export const Documentation = () => {
  return (
    <div className="relative min-h-screen flex flex-col items-center overflow-hidden">
      {/* Background Media */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ 
          duration: 1,
          ease: "easeInOut"
        }}
        className="fixed top-0 left-0 right-0 w-[100vw] h-full -z-10 overflow-hidden"
      >
        {/* Mobile Background Image */}
        <div className="block md:hidden w-full h-full">
          <img 
            src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/space2.jpg"
            alt="Space background"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Desktop Background Video */}
        <div className="hidden md:block w-full h-full">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            className="w-full h-full object-cover"
            style={{ transform: 'translate3d(0, 0, 0)' }}
          >
            <source src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/videos/space.mp4" type="video/mp4" />
          </video>
        </div>
      </motion.div>

      {/* Content */}
      <motion.div
        className="flex-grow flex items-center justify-center px-6 pt-24"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <div className="bg-gray-900/90 backdrop-blur-sm rounded-2xl p-12 shadow-2xl max-w-2xl w-full text-center border border-gray-700">
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <span className="text-6xl mb-6 block">🚀</span>
            <h1 className="text-3xl font-bold text-white mb-4">Documentation Coming Soon!</h1>
            <p className="text-gray-300 text-lg">
              We are currently working on the documentation for this project.
              Check back soon for detailed guides and resources!
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};
