import React, { useState, useEffect } from 'react';
import { motion, useTransform } from 'framer-motion';
import { sectionVariants } from './SectionVariants';

const AboutSection = ({ scrollProgress }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [startTyping, setStartTyping] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Adjust the text opacity animation
  const textOpacity = useTransform(
    scrollProgress,
    [0, 0.2, 0.4, 0.6], // Changed timing values
    [0, 1, 1, 0]        // Text will be fully visible in the middle
  );

  // Adjust the slide animations timing as well
  const firstLineX = useTransform(
    scrollProgress,
    [0, 0.2, 0.4, 0.6], // Match with opacity timing
    [-1500, 0, 0, -1500]
  );

  const secondLineX = useTransform(
    scrollProgress,
    [0, 0.2, 0.4, 0.6], // Match with opacity timing
    [1500, 0, 0, 1500]
  );

  const text1 = "Apollo transforms your crypto trading experience on Injective and Hyperliquid.";
  const text2 = "A set of intuitive tools designed to optimize your strategies, reduce complexity, and gain a competitive edge in the most dynamic markets.";

  return (
    <motion.section
      id="about"
      className="h-screen flex items-center justify-center bg-cover bg-center relative"
      style={{ 
        backgroundImage: 'url("https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/space.jpg")',
        willChange: 'transform'
      }}
      initial="hidden"
      whileInView="visible"
      variants={sectionVariants}
    >
      {/* Inner shadow overlay */}
      <div className="absolute inset-0 shadow-[inset_0px_0px_150px_rgba(0,0,0,0.9)] pointer-events-none"></div>

      {/* Overlay with blur and tint */}
      <div className="absolute inset-0 bg-black/70"></div>

      <div className="max-w-7xl w-full relative z-10">
        <motion.div className="max-w-4xl mx-auto text-center">
          {isMobile ? (
            // Mobile version - Static titles and simpler text animation
            <>
              <h2 className="text-4xl font-bold text-white leading-tight font-rajdhani mb-2">
                Trade <i>Smarter</i>
              </h2>
              <h2 className="text-4xl font-bold text-white leading-tight font-rajdhani mb-8">
                Not <strong>Harder</strong>
              </h2>
              <motion.div 
                className="text-lg text-gray-300 leading-relaxed font-press-start relative p-4 rounded-xl bg-black/80 shadow-[inset_0_0_50px_rgba(0,0,0,0.5)] backdrop-blur-sm min-h-[200px] flex flex-col justify-center mx-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                <p className="mb-6">{text1}</p>
                <p>{text2}</p>
              </motion.div>
            </>
          ) : (
            // Desktop version - Keep original animations
            <>
              <div className="overflow-hidden mb-2">
                <motion.h2 
                  style={{ x: firstLineX, opacity: textOpacity }}
                  className="text-4xl md:text-7xl font-bold text-white leading-tight font-rajdhani"
                >
                  Trade <i>Smarter</i>
                </motion.h2>
              </div>
              
              <div className="overflow-hidden">
                <motion.h2 
                  style={{ x: secondLineX, opacity: textOpacity }}
                  className="text-4xl md:text-7xl font-bold text-white leading-tight font-rajdhani"
                >
                  Not <strong>Harder</strong>
                </motion.h2>
              </div>

              {/* Original desktop version content */}
              <motion.div
                style={{ opacity: textOpacity }}
                className="text-xl md:text-2xl text-gray-300 mb-12 mt-8 leading-relaxed font-press-start relative p-8 rounded-xl bg-black/80 shadow-[inset_0_0_50px_rgba(0,0,0,0.5)] backdrop-blur-sm min-h-[200px] flex flex-col justify-center"
              >
                <span className="overflow-hidden mb-8">
                  <motion.span
                    style={{ x: firstLineX }}
                    className="inline-block text-glow"
                  >
                    {text1.split('').map((char, index) => (
                      <motion.span
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: startTyping ? 1 : 0 }}
                        transition={{
                          duration: 0.1,
                          delay: startTyping ? index * 0.05 : 0
                        }}
                      >
                        {char}
                      </motion.span>
                    ))}
                  </motion.span>
                </span>

                <span className="overflow-hidden">
                  <motion.span
                    style={{ x: secondLineX }}
                    className="inline-block text-glow"
                  >
                    {text2.split('').map((char, index) => (
                      <motion.span
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: startTyping ? 1 : 0 }}
                        transition={{
                          duration: 0.1,
                          delay: startTyping ? (text1.length * 0.05) + (index * 0.05) : 0
                        }}
                      >
                        {char}
                      </motion.span>
                    ))}
                  </motion.span>
                </span>

                {/* Blinking cursor */}
                <motion.span
                  className="inline-block w-2 h-5 bg-blue-400 ml-1"
                  animate={{ opacity: [1, 0] }}
                  transition={{ 
                    duration: 0.8, 
                    repeat: Infinity, 
                    repeatType: "reverse" 
                  }}
                  style={{
                    position: startTyping ? 'relative' : 'absolute',
                    left: startTyping ? 'auto' : '50%',
                    top: startTyping ? 'auto' : '50%',
                    transform: startTyping ? 'none' : 'translate(-50%, -50%)',
                    marginLeft: startTyping ? '4px' : '0'
                  }}
                />
              </motion.div>
            </>
          )}

          <div className="flex justify-center space-x-6 mt-8">
            <button 
              onClick={() => setStartTyping(true)}
              className="px-8 py-3 bg-black text-white rounded-lg hover:bg-white hover:text-black transition-colors text-lg font-orbitron"
            >
              <strong>How?</strong>
            </button>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default AboutSection;