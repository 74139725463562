import React, { useRef, useState, useEffect } from 'react';
import { motion, useTransform } from 'framer-motion';
import { sectionVariants } from './SectionVariants';

const CurrentSection = ({ scrollProgress }) => {
    const ref = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    
    useEffect(() => {
      const checkIfMobile = () => {
        setIsMobile(window.innerWidth < 768);
      };

      checkIfMobile();
      window.addEventListener('resize', checkIfMobile);

      return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    // Reversed animations - boxes appear when scrolling to component
    const leftBoxX = useTransform(
      scrollProgress,
      [0, 0.2, 0.4, 0.6],
      isMobile ? [0, 0, 0, 0] : [-1500, 0, 0, 0] // Now stays at center
    );

    const rightBoxX = useTransform(
      scrollProgress,
      [0, 0.2, 0.4, 0.6],
      isMobile ? [0, 0, 0, 0] : [1500, 0, 0, 0] // Now stays at center
    );

    const boxesY = useTransform(
      scrollProgress,
      [0, 0.2, 0.4, 0.6],
      isMobile ? [0, 0, 0, 0] : [200, 0, 0, 0] // Start below, move to center
    );

    const boxesOpacity = useTransform(
      scrollProgress,
      [0, 0.2, 0.4, 0.6],
      isMobile ? [1, 1, 1, 1] : [0, 1, 1, 1] // Fade in and stay visible
    );

    // Also adjust text animations to match
    const upperTextX = useTransform(
      scrollProgress, 
      [0, 0.2, 0.4, 0.6],
      isMobile ? [0, 0, 0, 0] : [-1500, 0, 0, 0]
    );

    const lowerTextX = useTransform(
      scrollProgress, 
      [0, 0.2, 0.4, 0.6],
      isMobile ? [0, 0, 0, 0] : [1500, 0, 0, 0]
    );

    const textOpacity = useTransform(
      scrollProgress, 
      [0, 0.2, 0.4, 0.6],
      isMobile ? [1, 1, 1, 1] : [0, 1, 1, 1]
    );

    return (
        <motion.section 
        ref={ref}
        id="current-offerings"
        className="flex items-center justify-center relative h-screen"
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
    >
      {isMobile ? (
        // Mobile version with original background and logo
        <div 
          className="absolute inset-0 w-full h-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: 'url("https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/mobilebg.png")',
            willChange: 'transform',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden'
          }}
        />
      ) : (
        // Desktop version keeps the video
        <video
          autoPlay
          muted
          loop
          className="absolute inset-0 w-full h-full object-cover"
          style={{ minHeight: '100vh' }}
          data-plays-in-view
        >
          <source src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/videos/astronaut5.webm" type="video/mp4" />
        </video>
      )}

      {/* Tint Overlay */}
      <div className="absolute inset-0 w-full h-full bg-black bg-opacity-50 z-[2]" />

      {/* Content */}
      {isMobile ? (
        // Mobile version - With logo and buttons
        <div className="relative z-[3] flex flex-col items-center justify-center space-y-12 w-full px-4">
          {/* Apollo Finance Logo */}
          <img 
            src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/Finance.png" 
            alt="Apollo Finance" 
            className="w-[80%] max-w-[400px] mb-8 relative z-20"
          />
          
          {/* Trading Bot Buttons */}
          <div className="flex flex-col items-center space-y-8 mt-12 relative z-20">
            <button className="w-[80%] max-w-[300px] bg-blue-500/20 text-blue-300 px-6 py-6 rounded-xl hover:bg-blue-500/30 transition-colors backdrop-blur-md border border-blue-500/30 shadow-lg">
              <a 
                href="https://t.me/apollofun_bot" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-2xl font-rajdhani font-bold block"
              >
                Injective Bot
              </a>
            </button>
            
            <button className="w-[80%] max-w-[300px] bg-purple-500/20 text-purple-300 px-6 py-6 rounded-xl hover:bg-purple-500/30 transition-colors backdrop-blur-md border border-purple-500/30 shadow-lg">
              <a 
                href="https://t.me/hyperliquid_apollo_bot" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-2xl font-rajdhani font-bold block"
              >
                Hyperliquid Bot
              </a>
            </button>
          </div>
        </div>
      ) : (
        // Desktop version with all the animations and content
        <div className="max-w-5xl w-full z-[3] text-center px-4 md:px-0">
          {/* Upper text with left slide */}
          <div className="overflow-hidden mb-2">
            <motion.span
              style={{ x: upperTextX, opacity: textOpacity }}
              className="text-4xl md:text-6xl font-bold text-white font-rajdhani"
            >
              Seamless
            </motion.span>
          </div>

          {/* Lower text with right slide */}
          <div className="overflow-hidden mb-12">
            <motion.span
              style={{ x: lowerTextX, opacity: textOpacity }}
              className="text-4xl md:text-6xl font-bold text-white font-rajdhani"
            >
              Telegram Integration
            </motion.span>
          </div>
          
          {/* Desktop boxes */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
            {/* First Box - Injective */}
            <motion.div 
              style={{ 
                x: leftBoxX,
                y: boxesY,
                opacity: boxesOpacity
              }}
              className="relative h-full pointer-events-none"
            >
              <div className="absolute inset-0 bg-blue-500/10 blur-2xl rounded-xl animate-pulse"></div>
              <div className="relative z-10 bg-white/5 backdrop-blur-lg border border-white/20 rounded-xl p-4 md:p-8 shadow-2xl hover:shadow-[0_0_30px_rgba(255,255,255,0.3)] transition-shadow duration-300 h-full flex flex-col">
                <div className="flex flex-col md:flex-row items-center justify-center mb-6">
                  <img 
                    src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/injective.png" 
                    alt="Injective Logo" 
                    className="w-16 h-16 mr-4"
                  />
                  <h3 className="text-2xl md:text-4xl font-semibold text-white/90 font-rajdhani text-center md:text-left">
                    Injective Native Trading Bot
                  </h3>
                </div>
                <ul className="text-2xl text-white/70 space-y-4 font-orbitron text-center flex-grow flex flex-col justify-between">
                  <div className="space-y-4">
                    <li className="flex flex-col items-center justify-center">
                      Trade directly on the Injective blockchain through Telegram
                      using our intuitive trading bot.
                    </li>
                    <li className="flex flex-col items-center justify-center">
                      Fast, easy to use, and designed for a seamless trading experience—all within the Telegram app.
                    </li>
                  </div>
                  <li className="flex items-center justify-center pt-4">
                    <button className="pointer-events-auto bg-blue-500/20 text-blue-300 px-6 py-3 rounded-lg hover:bg-blue-500/30 transition-colors">
                      <a 
                        href="https://t.me/apollofun_bot" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="transition-colors"
                      >
                        Telegram
                      </a>
                    </button>
                  </li>
                </ul>
              </div>
            </motion.div>

            {/* Second Box - Hyperliquid */}
            <motion.div 
              style={{ 
                x: rightBoxX,
                y: boxesY,
                opacity: boxesOpacity
              }}
              className="relative h-full pointer-events-none"
            >
              <div className="absolute inset-0 bg-purple-500/10 blur-2xl rounded-xl animate-pulse"></div>
              <div className="relative z-10 bg-white/5 backdrop-blur-lg border border-purple-200/20 rounded-xl p-4 md:p-8 shadow-2xl hover:shadow-[0_0_30px_rgba(168,85,247,0.3)] transition-shadow duration-300 h-full flex flex-col">
                <div className="flex flex-col md:flex-row items-center justify-center mb-6">
                  <img 
                    src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/hyperliquid2.png" 
                    alt="Hyperliquid Logo" 
                    className="w-16 h-16 mr-4"
                  />
                  <h3 className="text-2xl md:text-4xl font-semibold text-white/90 font-rajdhani text-center md:text-left">
                    Hyperliquid Spot Markets Bot
                  </h3>
                </div>
                <ul className="text-2xl text-white/70 space-y-4 font-orbitron text-center flex-grow flex flex-col justify-between">
                  <div className="space-y-4">
                    <li className="flex flex-col items-center justify-center">
                      Trade directly from your Telegram app, ensuring a smooth
                      and accessible experience.
                    </li>
                    <li className="flex flex-col items-center justify-center">
                      User-friendly interface: Navigate and trade spot markets effortlessly, anytime, anywhere.
                    </li>
                  </div>
                  <li className="flex items-center justify-center pt-4">
                    <button className="pointer-events-auto bg-purple-500/20 text-purple-300 px-6 py-3 rounded-lg hover:bg-purple-500/30 transition-colors">
                      <a 
                        href="https://t.me/hyperliquid_apollo_bot" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="transition-colors"
                      >
                        Telegram
                      </a>
                    </button>
                  </li>
                </ul>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </motion.section>
    );
};

export default CurrentSection;