// components/WalletButton.js
import React, { useState } from 'react';

export const WalletButton = ({ walletAddress, isConnecting, onConnect, onDisconnect }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const handleDisconnectClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDisconnect = () => {
    setShowConfirmation(false);
    onDisconnect();
  };

  const handleCancelDisconnect = () => {
    setShowConfirmation(false);
  };

  const formatAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(walletAddress);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000); // Hide after 2 seconds
    } catch (err) {
      console.error('Failed to copy address:', err);
    }
  };

  const buttonStyle = "min-w-[140px] px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-md shadow text-sm whitespace-nowrap";

  if (showConfirmation) {
    return (
      <div className="flex flex-row-reverse space-x-2 space-x-reverse">
        <button
          onClick={handleConfirmDisconnect}
          className={`${buttonStyle} bg-red-500 hover:bg-red-600`}
        >
          Confirm
        </button>
        <button
          onClick={handleCancelDisconnect}
          className={buttonStyle}
        >
          Cancel
        </button>
      </div>
    );
  }

  if (walletAddress) {
    return (
      <div className="flex flex-row-reverse space-x-2 space-x-reverse">
        <button
          onClick={handleDisconnectClick}
          className="min-w-[140px] px-4 py-2 bg-gray-700 hover:bg-red-500 hover:text-black rounded-md shadow text-sm whitespace-nowrap transition-colors duration-200"
        >
          Disconnect
        </button>
        <div className="relative">
          <button
            onClick={handleCopyAddress}
            className={`${buttonStyle} bg-gray-800 hover:bg-gray-700 cursor-pointer transition-colors duration-200`}
          >
            {formatAddress(walletAddress)}
          </button>
          {showCopied && (
            <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white px-2 py-1 rounded text-xs whitespace-nowrap">
              Copied!
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <button 
      onClick={onConnect}
      disabled={isConnecting}
      className={`${buttonStyle} disabled:opacity-50 disabled:cursor-not-allowed`}
    >
      {isConnecting ? 'Connecting...' : 'Connect Wallet'}
    </button>
  );
};