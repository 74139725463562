import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

if (!container) {
  console.error('Root element not found');
} else {
  try {
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Failed to render app:', error);
    container.innerHTML = `
      <div style="height: 100vh; width: 100vw; display: flex; flex-direction: column; justify-center; align-items: center; background: black; color: white; padding: 20px; text-align: center;">
        <h1 style="color: red; margin-bottom: 20px;">Failed to load Apollo</h1>
        <pre style="color: #ff6b6b; max-width: 800px; overflow-x: auto;">${error.message}</pre>
      </div>
    `;
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Add global error handler
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason);
});
