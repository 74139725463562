import React, { useState, useEffect } from 'react';
import { motion, useTransform } from 'framer-motion';

const HeroSection = ({ scrollProgress }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const marginTop = useTransform(
    scrollProgress,
    [0, 0.15],
    ['0px', '-100px']
  );

  return (
    <motion.section 
      id="hero"
      className={`${isMobile ? 'min-h-screen' : 'h-screen'} flex items-center justify-center relative overflow-hidden`}
      initial="hidden"
      animate="visible"
      style={{ 
        overflowY: 'hidden',
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '100vh'
      }}
    >
      {/* Background Image with adjusted animation */}
      <motion.div 
        className="absolute inset-0 w-full h-full object-cover z-0 bg-cover bg-center"
        style={{ 
          backgroundImage: 'url("https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/vector.png")',
          backgroundPosition: '50% 30%',
          backgroundSize: 'cover',
          transform: isMobile ? 'scale(1.1)' : 'scale(1.3) translateX(45px)',
          marginTop: isMobile ? 0 : marginTop,
          transformOrigin: 'center center',
          transition: 'transform 0.3s ease-out',
          willChange: 'transform',
          backfaceVisibility: 'hidden',
          WebkitBackfaceVisibility: 'hidden',
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 0 }}
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black/50 z-[1]"></div>

      {/* Center Logo with glow effect */}


      {/* Text Content */}
      <div className={`absolute ${isMobile ? 'bottom-[120px]' : 'bottom-[100px]'} w-full text-center z-30`}>
        {isMobile ? (
          <div className="flex flex-col items-center space-y-4 italic text-2xl md:text-3xl text-yellow-300 max-w-3xl mx-auto px-4 mb-16">
            <p><strong>Seamless</strong></p>
            <p><strong>Hyperliquid</strong></p>
            <p><strong>and</strong></p>
            <p><strong>Injective</strong></p>
            <p><strong>trading experience.</strong></p>
          </div>
        ) : (
          <div 
            className="italic text-3xl text-yellow-300 max-w-3xl mx-auto px-4 mb-1"
            style={{ 
              textShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)'
            }}
          >
            <p><strong>Seamless Hyperliquid and Injective trading experience.</strong></p>
          </div>
        )}
      </div>

      {/* Bottom Corner Text */}
      <div className="absolute bottom-12 w-full flex justify-between px-16 z-30">
        {/* Bottom Left - APOLLO */}
        <div className="text-white/30 text-7xl font-bold font-rajdhani tracking-[0.2em]">
          APOLLO
        </div>
        
        {/* Bottom Right - FINANCE */}
        <div className="text-white/30 text-7xl font-bold font-rajdhani tracking-[0.2em]">
          FINANCE
        </div>
      </div>
    </motion.section>
  );
};

export default HeroSection; 