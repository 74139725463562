import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { usePrivy } from '@privy-io/react-auth';
import axios from 'axios';

export const Dashboard = () => {
  const { user, login } = usePrivy();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/agents/`, {
          headers: {
            'uid': user?.id || ''
          }
        });
        
        const parsedAgents = response.data.map(agent => ({
          ...agent,
          details: JSON.parse(agent.character_json)
        }));
        
        setAgents(parsedAgents);
      } catch (err) {
        console.error('Error fetching agents:', err);
        setError('Failed to load agents');
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      fetchAgents();
    } else {
      setLoading(false);
    }
  }, [user?.id]);

  const handleDelete = async (agentId) => {
    if (window.confirm('Are you sure you want to delete this agent?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/agents/${agentId}/`, {
          headers: {
            'uid': user?.id || ''
          }
        });
        
        setAgents(agents.filter(agent => agent.id !== agentId));
      } catch (err) {
        console.error('Error deleting agent:', err);
        setError('Failed to delete agent');
      }
    }
  };

  const handleStatusToggle = async (agentId, currentStatus) => {
    try {
      const newStatus = currentStatus === 'running' ? 'stopped' : 'running';
      
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/agents/${agentId}/`, 
        { status: newStatus },
        {
          headers: {
            'uid': user?.id
          }
        }
      );

      setAgents(agents.map(agent => 
        agent.id === agentId 
          ? { ...agent, status: newStatus }
          : agent
      ));

      console.log(`Agent ${agentId} status updated to ${newStatus}`);
    } catch (err) {
      console.error('Error updating agent status:', err);
      setError(`Failed to ${currentStatus === 'running' ? 'stop' : 'start'} agent`);
    }
  };

  // If no wallet is connected
  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="bg-blue-600/20 backdrop-blur-lg rounded-lg p-8 text-center max-w-md mx-auto"
        >
          <h2 className="text-2xl font-bold text-white mb-4">Connect Your Wallet</h2>
          <p className="text-gray-300 mb-6">Connect your wallet to view and manage your agents</p>
          <button
            onClick={login}
            className="px-6 py-3 bg-blue-600 hover:bg-blue-500 rounded-lg text-white font-medium transition-colors"
          >
            Connect Wallet
          </button>
        </motion.div>
      </div>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-blue-600/20 backdrop-blur-sm rounded-lg p-8 text-center"
        >
          <div className="text-2xl font-bold text-white mb-2">Loading your agents</div>
          <div className="text-gray-300">Please wait while we fetch your agents...</div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col">
      {/* Background Media */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="fixed top-0 left-0 right-0 w-[100vw] h-full -z-10 overflow-hidden"
      >
        {/* Mobile Background Image */}
        <div className="block md:hidden w-full h-full">
          <img 
            src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/space2.jpg"
            alt="Space background"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Desktop Background Video */}
        <div className="hidden md:block w-full h-full">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            className="w-full h-full object-cover"
            style={{ transform: 'translate3d(0, 0, 0)' }}
          >
            <source src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/videos/space.mp4" type="video/mp4" />
          </video>
        </div>
      </motion.div>

      {/* Dashboard Content */}
      <motion.div 
        className="flex-grow px-6 py-8 mt-20 relative w-full h-[calc(100vh-80px)]"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="max-w-4xl mx-auto h-full flex flex-col">
          {/* "My Agents" header - only visible on desktop */}
          <div className="bg-black/60 backdrop-blur-lg rounded-lg p-6 shadow-lg flex-grow flex flex-col">
            {/* Header section with bottom border */}
            <div className="border-b border-gray-800 pb-4 mb-6 flex justify-between items-center">
              <h2 className="text-2xl font-semibold text-white">Your Agents</h2>
              <Link 
                to="/agents/create" 
                className="px-4 py-2 bg-white hover:bg-blue-500 rounded text-black transition-colors"
              >
                New Agent
              </Link>
            </div>
            
            {error && (
              <div className="text-red-500 mb-4">{error}</div>
            )}
            
            {agents.length === 0 ? (
              <div className="text-center py-8 flex-grow flex flex-col justify-center">
                <p className="text-gray-400 mb-4">You haven't created any agents yet.</p>
                <Link 
                  to="/agents/create" 
                  className="text-blue-400 hover:text-blue-300 underline"
                >
                  Create your first agent
                </Link>
              </div>
            ) : (
              <ul className="divide-y divide-gray-800/50 flex-grow">
                {agents.map((agent, index) => (
                  <li key={index} className="py-4 flex justify-between items-center first:pt-0 last:pb-0">
                    <div className="flex items-center space-x-4">
                      <div className="w-10 h-10 bg-gray-700/50 rounded-full flex items-center justify-center">
                        {agent.details.name[0]}
                      </div>
                      <div>
                        <h3 className="text-white font-medium">{agent.details.name}</h3>
                        <p className="text-gray-400 text-sm">
                          Status: <span className={`${
                            agent.status === 'running' ? 'text-green-400' : 'text-red-400'
                          }`}>{agent.status}</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <button 
                        onClick={() => handleStatusToggle(agent.id, agent.status)}
                        className={`px-3 py-1 rounded text-white text-sm ${
                          agent.status === 'running' 
                            ? 'bg-red-600 hover:bg-red-500' 
                            : 'bg-green-600 hover:bg-green-500'
                        }`}
                      >
                        {agent.status === 'running' ? 'Stop' : 'Start'}
                      </button>
                      <Link 
                        to={`/agents/edit/${agent.id}`}
                        className="text-blue-400 hover:text-blue-300 px-3 py-1"
                      >
                        Edit
                      </Link>
                      <button 
                        onClick={() => handleDelete(agent.id)}
                        className="text-red-400 hover:text-red-300 px-3 py-1"
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};
