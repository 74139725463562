import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { sectionVariants } from './SectionVariants';

const UpcomingFeatures = () => {
    const ref = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const checkIfMobile = () => {
        setIsMobile(window.innerWidth < 768);
      };

      checkIfMobile();
      window.addEventListener('resize', checkIfMobile);

      return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    // Remove if not using
    // const { scrollYProgress } = useScroll({
    //   target: ref,
    //   offset: ["start end", "end start"],
    //   smooth: true
    // });

    return ( 
    <motion.section 
      ref={ref}
      id="upcoming-features"
      className={`min-h-screen flex items-center justify-center p-4 md:p-12 bg-cover bg-center relative ${isMobile ? 'py-20' : ''}`}
      style={{ backgroundImage: 'url("https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/astro3.jpg")' }}
      initial="hidden"
      whileInView="visible"
      variants={sectionVariants}
    >
      {/* Overlay with blur and tint */}
      <div className="absolute inset-0 bg-black/50 backdrop-blur-[5px]"></div>

      <div className="max-w-7xl w-full relative z-10 mx-auto px-4">
        {/* Title with mobile adjustments */}
        <div className="relative w-screen left-1/2 -translate-x-1/2 overflow-hidden mb-6 md:mb-12">
          <h2 
            className="text-4xl md:text-7xl text-white font-bold text-center font-['Doto'] whitespace-nowrap"
            style={{ 
              textShadow: `
                0 0 10px rgba(255, 255, 255, 0.3),
                0 0 20px rgba(255, 255, 255, 0.3),
                2px 2px 4px rgba(0, 0, 0, 0.8)
              `
            }}
          >
            UPCOMING FEATURES UPCOMING FEATURES UPCOMING FEATURES UPCOMING FEATURES
          </h2>
        </div>

        <div className={`grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-9 max-w-6xl mx-auto ${isMobile ? 'px-4 items-center justify-items-center' : ''}`}>
          {[
            {
              icon: "/images/1.png",
              title: "AI Agents",
              description: "Personalize your own AI Agents very soon in Apollo Agents",
              bg: "/images/",
              scale: "scale-[1.2]"
            },
            {
              icon: "/images/3.png",
              title: "Index",
              description: "A set of tools designed to optimize your strategies, including a market index.",
              bg: "/images/",
              scale: "scale-[1]"
            },
            {
              icon: "/images/2.png",
              title: "Perps",
              description: "PERP Trading coming soon to our Injective and Hyperliquid trading bots.",
              bg: "/images",
              scale: "scale-[1.2]"
            }
          ].map((feature, index) => (
            <motion.div 
              key={feature.title}
              className="relative overflow-hidden rounded-xl border border-slate-900 shadow-2xl p-1 text-center flex flex-col items-center min-h-[350px] md:min-h-[500px] w-full max-w-[350px]"
              variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { 
                  opacity: 1, 
                  scale: 1, 
                  transition: { 
                    duration: 0.6, 
                    delay: index * 0.2 
                  } 
                }
              }}
            >
              {/* Top Half - White Background */}
              <div className="absolute inset-x-0 top-0 h-1/2 bg-white/20 backdrop-blur-lg" />
              
              {/* Bottom Half - Image Background */}
              <div 
                className="absolute inset-x-0 bottom-0 h-1/2 bg-cover bg-center" 
                style={{ 
                  backgroundImage: `url(${feature.bg})`,
                  opacity: 1.0
                }}
              >
                {/* Add separate overlay div for the tint */}
                <div className="absolute inset-0 bg-black/90" />
              </div>
              
              {/* Gradient Overlay for smooth transition */}
              <div className="absolute inset-0 bg-gradient-to-b from-white/5 via-transparent to-black/50" />

              <div className="w-full flex flex-col items-center pt-1 relative z-10 overflow-visible">
                <img 
                  src={feature.icon} 
                  alt={`${feature.title} Icon`} 
                  className={`w-full max-w-[250px] md:max-w-none object-contain mb-2 transform ${feature.scale}`}
                />
                <h3 className="text-lg md:text-2xl font-semibold mb-2 font-rajdhani text-white px-2">
                  {feature.title}
                </h3>
                <p className="text-sm md:text-lg lg:text-xl font-orbitron text-white text-glow mb-[30px] md:mb-[110px] px-4">
                  {feature.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default UpcomingFeatures;    