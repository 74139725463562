import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const scrollToCurrentSection = () => {
    const currentSection = document.getElementById('current-offerings');
    if (currentSection) {
      currentSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAIAgentsClick = () => {
    navigate('/agents');
  };

  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-50 bg-black/30 backdrop-blur-md border-b border-white/10">
        {isMobile ? (
          // Mobile version - Simple centered text
          <div className="flex items-center justify-center py-4">
            <div className="text-white text-xl font-rajdhani font-bold">
              APOLLO 🚀 FINANCE
            </div>
          </div>
        ) : (
          // Desktop version - With responsive centering
          <div className="relative w-full">
            <div className="max-w-full px-4 sm:px-6 lg:px-2">
              <div className="flex justify-between items-center h-16">
                {/* Left side - Empty for balance */}
                <div className="w-[300px]"></div>

                {/* Center - Apollo Logo with responsive positioning */}
                <div 
                  className="absolute left-1/2 flex items-center transition-all duration-300"
                  style={{
                    transform: 'translateX(calc(-50% + min(0px, (100vw - 1400px) / 4)))'
                  }}
                >
                  <h1 className="text-3xl font-bold text-white font-rajdhani whitespace-nowrap">Apollo Finance</h1>
                </div>

                {/* Right side - Navigation and Links */}
                <div className="flex items-center gap-4 flex-shrink-0">
                  {/* Trading Bots & AI Agents */}
                  <button 
                    onClick={scrollToCurrentSection}
                    className="px-4 py-2 bg-white text-black rounded-md hover:bg-gray-100 transition-colors font-medium"
                  >
                    Trading Bots
                  </button>
                  <button 
                    onClick={handleAIAgentsClick}
                    className="px-4 py-2 bg-white text-black rounded-md hover:bg-gray-100 transition-colors font-medium"
                  >
                    AI Agents
                  </button>

                  {/* X (Twitter) Logo */}
                  <a 
                    href="https://x.com/ApolloDotLoan"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transform transition-all duration-300 hover:scale-110 hover:brightness-125"
                  >
                    <img 
                      src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/x.png" 
                      alt="X (Twitter)" 
                      className="h-[53px] w-auto"
                    />
                  </a>
                  
                  {/* DEX Screener Logo */}
                  <a 
                    href="https://dexscreener.com/solana/b5rg1mxjtofbsttmyavhmox1tx4wvcdm1nykrfz9cnfi"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transform transition-all duration-300 hover:scale-110 hover:brightness-125"
                  >
                    <img 
                      src="https://appbot.nyc3.digitaloceanspaces.com/Apollo/images/dex.png" 
                      alt="DEX Screener" 
                      className="h-[120px] w-auto"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>

      {/* Coming Soon Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Backdrop */}
          <div 
            className="absolute inset-0 bg-black/70 backdrop-blur-sm"
            onClick={() => setShowModal(false)}
          />
          
          {/* Modal Content */}
          <div className="relative z-10 max-w-md w-full mx-4">
            <div className="relative">
              <div className="absolute inset-0 bg-purple-500/10 blur-2xl rounded-xl animate-pulse"></div>
              <div className="relative z-10 bg-white/5 backdrop-blur-lg border border-purple-200/20 rounded-xl p-8 shadow-2xl">
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-3xl text-white/90 font-semibold font-rajdhani text-glow">
                    🚀 Coming Soon
                  </h3>
                  <button 
                    onClick={() => setShowModal(false)}
                    className="text-white/50 hover:text-white transition-colors"
                  >
                    ✕
                  </button>
                </div>
                <p className="text-xl text-white font-orbitron text-center text-glow">
                  AI Agents feature is currently in development. Stay tuned for updates!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header; 